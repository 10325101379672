import { Modal, Notification } from "@douyinfe/semi-ui";
import axios from "axios";
import pinyin from "pinyin";
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// 防抖白名单
const DEBOUNCE_WHITELIST = [
    'Srapp.Web_User_Infos.UserAddress',
    'Srapp.Web_SystemInfo.RepairPartsList'
];

// 节流Map，用于存储接口的最后调用时间
const throttleMap = new Map();
const THROTTLE_TIME = 6000; // 6秒节流时间

// 节流函数
const throttle = (url) => {
    if (url.includes('Report')) {
        const lastTime = throttleMap.get(url);
        const now = Date.now();

        if (lastTime && now - lastTime < THROTTLE_TIME) {
            const remainTime = Math.ceil((THROTTLE_TIME - (now - lastTime)) / 1000);
            return {
                throttled: true,
                message: `请求过于频繁，请${remainTime}秒后再试`
            };
        }

        throttleMap.set(url, now);
    }
    return { throttled: false };
};

// 防抖函数
const debounceMap = new Map();
const debounce = (fn, url, delay = 500) => {
    return function (...args) {
        // 从参数中获取实际的接口URL
        let apiUrl = '';
        if (args[1] && typeof args[1] === 'object' && args[1].url) {
            apiUrl = args[1].url;
        }

        // 检查是否在白名单中
        const isInWhitelist = apiUrl && DEBOUNCE_WHITELIST.some(whiteUrl => apiUrl.includes(whiteUrl));
        if (isInWhitelist) {
            return fn.apply(this, args);  // 如果在白名单中，直接调用原函数
        }

        if (debounceMap.has(url)) {
            clearTimeout(debounceMap.get(url));
        }
        return new Promise((resolve) => {
            const timer = setTimeout(async () => {
                debounceMap.delete(url);
                const result = await fn.apply(this, args);
                resolve(result);
            }, delay);
            debounceMap.set(url, timer);
        });
    };
};

axios.defaults.timeout = 100000;
// 使用环境变量设置 baseURL
axios.defaults.baseURL = process.env.REACT_APP_API_URL || "https://sdapiweb2.sanrangas.com/";
// axios.defaults.baseURL = "https://sdapiweb.sanrangas.com/";
// axios.defaults.baseURL = "http://testapi.sanrangas.com/";
// axios.defaults.baseURL = "http://srsdapi.test/";

const token = localStorage.getItem('token') || '';

axios.interceptors.request.use(
    (config) => {
        if (
            config.data?.url != 'Srapp.Web_SystemInfo.GetWorkDepdeliverymanList'
            && config.data?.url != 'Srapp.Web_HomePageInformation.GetBasicOverviewOfUserGroups'
            && config.data?.url != 'Srapp.Web_HomePageInformation.GetTotalOrderGoodsDistributionTime'
            && config.data?.url != 'Srapp.Web_HomePageInformation.GetTotalSalesSecurityCheck'
            && config.data?.url != 'Srapp.Web_HomePageInformation.GetWorkDepdeliverymanList'
            && config.data?.url != 'Srapp.Web_User_Infos.UserOtherServicesOrderInfo'
            && config.data?.url != 'Srapp.Web_User_Infos.UserAddress'
            && config?.url != 'http://116.10.197.126:8600/company/bind'
            && config?.url != '/api/GetComDepartmentList'
            && config?.url != '/api/getInitData'
        ) {
            const modalid = Modal.info();
            config.modalid = modalid;
            modalid.update({
                title: '数据加载中...',
                content: '正在请求接口,请稍等...',
                footer: null,
                centered: true,
                bodyStyle: {
                    paddingBottom: 50,
                    borderRadius: 0,
                },
                maskClosable: false
            });
        }

        config.data = JSON.stringify(config.data);
        config.headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        };
        return config;
    },
    (error) => Promise.reject(error)
);

const codeMessgae = {
    200: '服务器成功返回请求的数据。',
    201: '新建数据成功。',
    202: '一个请求已经静如后台排队（异步任务）。',
    204: '处理成功。',
    400: '发出的请求有错误。'
};

function getInitials(chineseText) {
    const pinyinTextArray = pinyin(chineseText, { style: 'normal' });
    const initials = pinyinTextArray[0][0];
    return initials.toUpperCase().substring(0, 1);
}

axios.interceptors.response.use(
    (response) => {
        if (response.config.modalid) {
            response.config.modalid.destroy();
        }
        return response;
    },
    async (error) => {
        console.log('error', error);
        if (error.config?.modalid) {
            error.config.modalid.destroy();
        }
        const { response } = error;
        let errorText = '';

        // 超时处理
        if (error === undefined || error.code === 'ECONNABORTED') {
            toast.error('服务请求超时');
            return Promise.reject(error);
        }

        // 服务端常见错误
        if (response?.status === 501 || response?.status === 507 || response?.status === 500) {
            const text = await response.data;
            if (response?.status === 500) {
                errorText = '登录失效，请重新登录';
            } else {
                errorText = text.rew?.msg || text.data?.tips || text.rew?.data?.tips || '服务器错误';
            }
            toast.error(errorText, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } else {
            errorText = response?.data?.tips || '未知错误';
            // 过滤掉特定接口的报错
            if (response?.config.url !== '/api/getInitData' && window.location.pathname !== '/login') {
                toast.error(errorText, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }

        return Promise.reject(error);
    }
);

/** get 请求 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
}

/**
 * post 请求
 * - 对普通的 post 接口做防抖和节流
 * - 如果检测到 token 过期，则优先刷新 token 再发请求
 */
export function post(url, data) {
    console.log('当前时间: ', new Date().getTime());
    console.log('expire_time x 1000: ', localStorage.getItem('expire_time') * 1000);

    // 先检查 token 是否已过期
    if (
        localStorage.getItem('expire_time') &&
        new Date().getTime() > (localStorage.getItem('expire_time') * 1000)
    ) {
        // 已过期，先刷新
        console.log('检测到 token 已过期，开始刷新 token');
        return refreshToken().then(() => {
            // 刷新成功后，再发起原来的 post
            return doPost(url, data);
        }).catch(err => {
            console.error('刷新 token 失败', err);
            // 如果刷新失败，依旧返回一个 reject，让调用方决定要不要跳转登录等
            return Promise.reject(err);
        });
    } else {
        // 如果没过期，直接调用
        return doPost(url, data);
    }
}

/**
 * 真正执行 post 的函数，做节流和防抖
 */
function doPost(url, data) {
    return new Promise((resolve, reject) => {
        // 去除字符串里的 \u0000
        for (const i in data) {
            if (typeof data[i] === 'string') {
                data[i] = data[i].trim();
                data[i] = data[i].replace(/\u0000/g, '');
            }
        }
        console.log('提交参数 data', data);

        // 检查是否需要节流
        if (data.url) {
            const throttleResult = throttle(data.url);
            if (throttleResult.throttled) {
                toast.warning(throttleResult.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                // 返回与正常接口一致的数据结构
                resolve({
                    code: -1,
                    data: {
                        msg: 'THROTTLED',
                        tips: throttleResult.message,
                        info: []
                    }
                });
                return;
            }
        }

        // 使用封装好的防抖 post
        debounce(axios.post, url, 500)(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * 刷新 token 的请求：不使用节流 / 防抖，直接请求
 */
function refreshToken() {
    console.log('[refreshToken] 正在调用刷新接口...');
    return axios.post(
        '/api/getInfo',
        {
            url: 'Srapp.Web_Auth.RefreshToken',
            refresh_token: localStorage.getItem('refresh_token'),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }
    ).then((res) => {
        console.log('[refreshToken] 刷新接口返回: ', res.data);
        const info = res.data?.data?.info;
        if (info) {
            localStorage.setItem('token', info.token);
            localStorage.setItem('refresh_token', info.refresh_token);
            localStorage.setItem('expire_time', info.expire_time);
            localStorage.setItem('refresh_token_expires', info.refresh_token_expires);
            console.log('[refreshToken] 更新本地token成功');
        } else {
            // 如果后端返回里拿不到 info，说明刷新接口没成功
            throw new Error('刷新 token 失败, 未获取到 info');
        }
    }).catch((err) => {
        console.error('[refreshToken] 请求出错: ', err);
        Notification.info({
            title: '提示',
            content: '登录失效，请重新登录',
            duration: 0,
            zIndex: 9999,
        });
        // 这里也可以清空 localStorage 并跳转登录页
        return Promise.reject(err);
    });
}

/** patch 请求 */
export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err));
    });
}

/** put 请求 */
export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err));
    });
}

/**
 * 默认导出的通用请求函数
 */
export default function (fetchType, url, param) {
    return new Promise((resolve, reject) => {
        switch (fetchType) {
            case "get":
                console.log("begin a get request, and url:", url);
                get(url, param)
                    .then((response) => resolve(response))
                    .catch((error) => {
                        console.log("get request GET failed.", error);
                        reject(error);
                    });
                break;

            case "post":
                post(url, param)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log("get request POST failed.", error);
                        console.log("get request POST failed.", error.response);

                        if (error !== 'Error: Network Error' && error.response?.status !== 401 && error.response?.status !== 500) {
                            if (error.response?.status === 400) {
                                toast.error(`${error.response?.data.tips}`);
                            } else {
                                toast.error(`接口错误 ${error.response?.data.rew?.msg}`);
                            }
                        }

                        if (error.response?.status === 401) {
                            console.log("登录失效", error.response);
                            // 可以在这里弹窗或直接跳转
                            // window.location.href = '/login'
                        }
                        reject(error);
                    });
                break;

            default:
                break;
        }
    });
}

function msag(err) {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                alert(err.response.data.error.details);
                break;
            case 401:
                alert("未授权，请登录");
                break;
            case 403:
                alert("拒绝访问");
                break;
            case 404:
                alert("请求地址出错");
                break;
            case 408:
                alert("请求超时");
                break;
            case 500:
                alert("服务器内部错误");
                break;
            case 501:
                alert("服务未实现");
                break;
            case 502:
                alert("网关错误");
                break;
            case 503:
                alert("服务不可用");
                break;
            case 504:
                alert("网关超时");
                break;
            case 505:
                alert("HTTP版本不受支持");
                break;
            default:
        }
    }
}
