import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
     Box,
     Dialog,
     DialogTitle,
     DialogContent,
     IconButton,
     Typography,
     List,
     ListItem,
     ListItemText,
     ListItemSecondaryAction,
     TextField,
     Tooltip,
     Tabs,
     Tab,
     Select,
     MenuItem
} from '@mui/material';
import { IconHelp, IconX } from '@tabler/icons';
import PropTypes from 'prop-types';
import pinyin from 'pinyin';

// 常见问题和跳转
const commonQuestions = [
     {
          question: '如何查看会员信息?',
          answer: '点击顶部搜索框输入会员卡号，或使用菜单搜索功能找到"会员管理"',
          path: '/member/list'
     },
     {
          question: '如何处理订单?',
          answer: '在订单管理页面可以进行订单的处理和跟踪',
          path: '/order/list'
     },
     {
          question: '如何查看系统公告?',
          answer: '点击顶部的"系统公告"按钮即可查看所有公告',
          path: '/notice'
     }
];

// 默认常用菜单
const defaultMenus = [
     {
          title: '会员查询',
          url: '/users/UserBasicInfo',
          description: '查询会员信息'
     },
     {
          title: '开户办卡',
          url: '/users/AddUserBasicInfo',
          description: '新用户开户办理'
     },
     {
          title: '办理抵押物',
          url: '/users/business',
          description: '办理抵押物业务'
     },
     {
          title: '物资信息',
          url: '/report/TraceTheSourceMaterialStockReport',
          description: '查看物资信息'
     },
     {
          title: '收款报表',
          url: '/report/CollectionReportOfFinance',
          description: '查看收款报表'
     }
];

// 从localStorage获取菜单列表
const getMenuList = () => {
     const loginuser = JSON.parse(localStorage.getItem('userinfo'));
     // console.log('loginuser:', loginuser);
     if (loginuser?.quarters_webmenu1) {
          try {
               // console.log('quarters_webmenu1 raw:', loginuser.quarters_webmenu1);
               const menuData = JSON.parse(loginuser.quarters_webmenu1);
               // console.log('menuData parsed:', menuData);

               // 递归提取所有菜单项
               const extractMenuItems = (items) => {
                    let result = [];
                    if (!Array.isArray(items)) {
                         console.log('items is not array:', items);
                         return result;
                    }

                    items.forEach(item => {
                         if (item.type === 'item' && item.url) {
                              result.push({
                                   title: item.title,
                                   url: item.url,
                                   description: item.title
                              });
                         }
                         if (item.children && Array.isArray(item.children)) {
                              result = result.concat(extractMenuItems(item.children));
                         }
                    });
                    return result;
               };

               let finalResult = [];
               if (Array.isArray(menuData)) {
                    finalResult = extractMenuItems(menuData);
               } else if (menuData.items && Array.isArray(menuData.items)) {
                    finalResult = extractMenuItems(menuData.items);
               }
               console.log('Final menu list:', finalResult);
               return finalResult;
          } catch (error) {
               console.error('解析菜单数据失败:', error);
               return [];
          }
     }
     return [];
};

const ShortcutHelper = ({ open, setOpen }) => {
     const navigate = useNavigate();
     const [searchTerm, setSearchTerm] = useState('');
     const searchRef = useRef(null);
     const [activeTab, setActiveTab] = useState(0);
     const [customMenus, setCustomMenus] = useState([]);
     const [menuList, setMenuList] = useState([]);
     const [selectedMenu, setSelectedMenu] = useState('');

     // 从本地存储加载自定义菜单和菜单列表
     useEffect(() => {
          const savedMenus = localStorage.getItem('customMenus');
          if (savedMenus) {
               setCustomMenus(JSON.parse(savedMenus));
          } else {
               setCustomMenus(defaultMenus);
               localStorage.setItem('customMenus', JSON.stringify(defaultMenus));
          }
          const menuItems = getMenuList();
          console.log('Setting menu list:', menuItems);
          setMenuList(menuItems);
     }, []);

     const handleOpen = () => {
          setOpen(true);
          setTimeout(() => {
               if (searchRef.current) {
                    searchRef.current.focus();
               }
          }, 100);
     };

     const handleClose = () => {
          setOpen(false);
          setSearchTerm('');
          setActiveTab(0);
          setSelectedMenu('');
     };

     const handleQuestionClick = (path) => {
          navigate(path);
          handleClose();
     };

     const handleTabChange = (event, newValue) => {
          setActiveTab(newValue);
          setSearchTerm('');
     };

     const handleAddMenu = (url, menuItem) => {
          const newMenu = {
               title: menuItem.title,
               url: url,
               description: menuItem.description || menuItem.title
          };
          const updatedMenus = [...customMenus, newMenu];
          setCustomMenus(updatedMenus);
          localStorage.setItem('customMenus', JSON.stringify(updatedMenus));
          setSelectedMenu('');
     };

     const handleRemoveMenu = (index) => {
          const updatedMenus = customMenus.filter((_, i) => i !== index);
          setCustomMenus(updatedMenus);
          localStorage.setItem('customMenus', JSON.stringify(updatedMenus));
     };

     const filteredQuestions = commonQuestions.filter(
          item => item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
               item.answer.toLowerCase().includes(searchTerm.toLowerCase())
     );

     const filteredMenus = customMenus.filter(
          item => item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
               item.description?.toLowerCase().includes(searchTerm.toLowerCase())
     );

     const filterMenuList = (input, option) => {
          const title_pinyin_arr = pinyin(option?.title || '', { style: 'normal' });
          const title_pinyin = title_pinyin_arr.join('');
          return (option?.title?.includes(input)) || (title_pinyin.includes(input));
     };

     return (
          <>
               <Tooltip title="业务助手">
                    <IconButton
                         sx={{
                              ml: 1,
                              '&:hover': { background: 'rgba(0,0,0,0.05)' }
                         }}
                         onClick={handleOpen}
                         size="large"
                    >
                         <IconHelp stroke={1.5} size="1.3rem" />
                    </IconButton>
               </Tooltip>

               <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                         sx: {
                              borderRadius: 2,
                              maxHeight: '80vh'
                         }
                    }}
               >
                    <DialogTitle>
                         <Box display="flex" alignItems="center" justifyContent="space-between">
                              <Typography variant="h3">业务助手</Typography>
                              <IconButton onClick={handleClose} size="small">
                                   <IconX />
                              </IconButton>
                         </Box>
                    </DialogTitle>

                    <DialogContent>
                         <TextField
                              inputRef={searchRef}
                              fullWidth
                              placeholder={activeTab === 0 ? "搜索问题..." : "搜索菜单..."}
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              sx={{ mb: 2 }}
                         />

                         <Tabs
                              value={activeTab}
                              onChange={handleTabChange}
                              sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
                         >
                              <Tab label="常见问题" />
                              <Tab label="常用菜单" />
                         </Tabs>

                         {activeTab === 0 ? (
                              <List>
                                   {filteredQuestions.map((item, index) => (
                                        <ListItem
                                             key={index}
                                             button
                                             onClick={() => handleQuestionClick(item.path)}
                                             sx={{
                                                  borderRadius: 1,
                                                  mb: 1,
                                                  '&:hover': {
                                                       backgroundColor: 'primary.lighter'
                                                  }
                                             }}
                                        >
                                             <ListItemText
                                                  primary={item.question}
                                                  secondary={item.answer}
                                                  primaryTypographyProps={{
                                                       variant: 'subtitle1',
                                                       color: 'primary.main'
                                                  }}
                                             />
                                        </ListItem>
                                   ))}
                              </List>
                         ) : (
                              <>
                                   <Box mb={2}>
                                        <Select
                                             fullWidth
                                             displayEmpty
                                             value={selectedMenu}
                                             onChange={(e) => {
                                                  const menuItem = menuList.find(item => item.url === e.target.value);
                                                  if (menuItem) {
                                                       handleAddMenu(e.target.value, menuItem);
                                                  }
                                             }}
                                             renderValue={(selected) => {
                                                  if (!selected) {
                                                       return "选择要添加的菜单...";
                                                  }
                                                  const menuItem = menuList.find(item => item.url === selected);
                                                  return menuItem?.title || selected;
                                             }}
                                        >
                                             {Array.isArray(menuList) && menuList.map((item) => (
                                                  <MenuItem key={item.url} value={item.url}>
                                                       {item.title}
                                                  </MenuItem>
                                             ))}
                                        </Select>
                                   </Box>

                                   <List>
                                        {filteredMenus.map((item, index) => (
                                             <ListItem
                                                  key={index}
                                                  button
                                                  onClick={() => handleQuestionClick(item.url)}
                                                  sx={{
                                                       borderRadius: 1,
                                                       mb: 1,
                                                       '&:hover': {
                                                            backgroundColor: 'primary.lighter'
                                                       }
                                                  }}
                                             >
                                                  <ListItemText
                                                       primary={item.title}
                                                       secondary={item.description}
                                                       primaryTypographyProps={{
                                                            variant: 'subtitle1',
                                                            color: 'primary.main'
                                                       }}
                                                  />
                                                  <ListItemSecondaryAction>
                                                       <IconButton
                                                            edge="end"
                                                            onClick={(e) => {
                                                                 e.stopPropagation();
                                                                 handleRemoveMenu(index);
                                                            }}
                                                            size="small"
                                                       >
                                                            <IconX size="1rem" />
                                                       </IconButton>
                                                  </ListItemSecondaryAction>
                                             </ListItem>
                                        ))}
                                   </List>
                              </>
                         )}
                    </DialogContent>
               </Dialog>
          </>
     );
};

ShortcutHelper.propTypes = {
     open: PropTypes.bool,
     setOpen: PropTypes.func
};

export default ShortcutHelper; 